import React, { useState, useEffect } from "react";
import algoliasearch from 'algoliasearch/lite';

// Utility functions integrated directly into the component
const parseResultsUrl = (location) => {
    const params = new URLSearchParams(location.search);
    return {
        departmentVal: params.get("department") || "",
        searchtypeVal: params.get("searchtype") || "",
        areaVal: params.get("area") || "",
        bedVal: params.get("beds") || "",
        minpriceVal: params.get("minprice") || "",
        maxpriceVal: params.get("maxprice") || "",
        statusVal: params.get("status") || "",
        buildingval: params.get("building") || "",
        pageVal: parseInt(params.get("page")) || 1,
        layoutVal: params.get("layout") || "",
        sortVal: params.get("sort") || "",
        indexVal: params.get("index") || "prod_properties",
    };
};

const generateFiltersfromPageData = (
    department, searchType, area, beds, minPrice, maxPrice, status, building
) => {
    let filters = [];
    if (department) filters.push(`department:${department}`);
    if (searchType) filters.push(`search_type:${searchType}`);
    if (area) filters.push(`area:${area}`);
    if (beds) filters.push(`beds:${beds}`);
    if (minPrice) filters.push(`price >= ${minPrice}`);
    if (maxPrice) filters.push(`price <= ${maxPrice}`);
    if (status) filters.push(`status:${status}`);
    if (building) filters.push(`building:${building}`);
    return filters.join(" AND ");
};

const createResultsUrl = (params) => {
    const query = new URLSearchParams(params).toString();
    return `/search-results?${query}`;
};

export default function SearchResults(props) {
    const [algoliaData, setAlgoliaData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Parse URL parameters
    const pageUrlData = parseResultsUrl(props.location);

    // Generate filters
    const algoliaFilters = generateFiltersfromPageData(
        pageUrlData.departmentVal,
        pageUrlData.searchtypeVal,
        pageUrlData.areaVal,
        pageUrlData.bedVal,
        pageUrlData.minpriceVal,
        pageUrlData.maxpriceVal,
        pageUrlData.statusVal,
        pageUrlData.buildingval
    );

    // Fetch Algolia data
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);

            try {
                const client = algoliasearch(
                    process.env.GATSBY_ALGOLIA_APP_ID,
                    process.env.GATSBY_ALGOLIA_SEARCH_API_KEY
                );
                const index = client.initIndex(pageUrlData.sortVal || pageUrlData.indexVal);

                const { nbHits, page, hitsPerPage, nbPages, hits } = await index.search(
                    pageUrlData.areaVal === process.env.GATSBY_DEFAULT_AREA ? "" : pageUrlData.areaVal,
                    {
                        filters: algoliaFilters,
                        page: pageUrlData.pageVal - 1,
                        hitsPerPage: pageUrlData.layoutVal ? 1000 : 21,
                        attributesToRetrieve: ["title", "price", "display_address", "slug", "status"],
                    }
                );

                setAlgoliaData({
                    total: nbHits,
                    current_page_number: page,
                    hitsPerPage,
                    number_of_pages: nbPages,
                    hits,
                });
            } catch (err) {
                setError("Failed to fetch results. Please try again.");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [props.location]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <React.Fragment>
            <div>
                <h1>Search Results</h1>
                <p>Total Results: {algoliaData.total}</p>
                <ul>
                    {algoliaData.hits.map((hit) => (
                        <li key={hit.slug}>
                            <h2>{hit.title}</h2>
                            <p>Price: {hit.price}</p>
                            <p>Address: {hit.display_address}</p>
                            <p>Status: {hit.status}</p>
                        </li>
                    ))}
                </ul>
                <p>
                    Page {algoliaData.current_page_number + 1} of {algoliaData.number_of_pages}
                </p>
                <button
                    onClick={() => (window.location.href = createResultsUrl({ ...pageUrlData, page: pageUrlData.pageVal - 1 }))}
                    disabled={pageUrlData.pageVal === 1}
                >
                    Previous
                </button>
                <button
                    onClick={() => (window.location.href = createResultsUrl({ ...pageUrlData, page: pageUrlData.pageVal + 1 }))}
                    disabled={pageUrlData.pageVal === algoliaData.number_of_pages}
                >
                    Next
                </button>
            </div>
        </React.Fragment>
    );
}
